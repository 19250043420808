import  * as R from 'ramda';
import {useEffect} from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import storage from '../storage';
import { ScreenStackElement, useRivalitasStore } from '../store';

function useJob(label: string|undefined = undefined) {
  const navigate = useNavigate();
  const location = useLocation();

  const { pushScreen, removeScreen, screenStack } = useRivalitasStore();
  const currentUrl = location.pathname;
  const fullUrl = `${location.pathname}${location.search}`

  useEffect(() => {
    if (!!label) {
      pushScreen(currentUrl, label, fullUrl);
    }
  }, [currentUrl, label, pushScreen, fullUrl]);

  function cleanupStorage(url: string) {
    const storageKeys = Object.keys(storage);
    const storageUrlDelimiter = `${url}|`;
    const foundKeys = storageKeys.filter(item => item.startsWith(storageUrlDelimiter));
    if(foundKeys.length) {
      foundKeys.forEach(foundKey => {
        delete storage[foundKey];
      })
    }
  }

  function doCloseJobAndOpenNext(urlToRemove: string, nextScreenUrl: string|undefined = undefined) {
    let nextScreenUrlToOpen = nextScreenUrl;

    if (!nextScreenUrlToOpen) {
      // Find element with max time
      const nextScreenCandidates = screenStack.filter(x => x.url !== urlToRemove);
      const nextScreen = R.reduce(R.maxBy((s: ScreenStackElement) => s.time),
        nextScreenCandidates[0],
        nextScreenCandidates);

      nextScreenUrlToOpen = nextScreen.fullUrl;
    }

    cleanupStorage(urlToRemove);

    removeScreen(urlToRemove);

    openJob(nextScreenUrlToOpen);
  }

  /**
   * If url is undefined - close current job
   *
   * @param {(string|undefined)} [url=undefined]
   */
  function closeJob(url: string|undefined = undefined) {
    const urlToRemove = url || currentUrl;

    doCloseJobAndOpenNext(urlToRemove);
  }

  function closeCurrentAndOpenNext(nextUrl: string) {
    doCloseJobAndOpenNext(currentUrl, nextUrl);
  }

  function openJob(url: string) {
    if (url !== currentUrl) {
      // Duplicate push so we can handle back button in correct way
      navigate(url);
      navigate(url);
    }
  }

  return {
    openJob,
    currentUrl,
    closeJob,
    closeCurrentAndOpenNext,
  };
}

export default useJob;
