import React from 'react';
import useJob from '../hooks/useJob';

function Home() {
  useJob('Home');

  return (
    <div>
    </div>
  );
}

export default React.memo(Home);