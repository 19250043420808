import _ from 'lodash';
import axios from 'axios';
import config from '../config';
import { RivalitasUser } from '../models';

export interface IRivalitasApi {
  setAccessToken: (accessToken: string) => void,
  list: (query: any) => Promise<any>,
  command: (type: string, command: any) => Promise<any>,
  delete: (entity: string, entityId: string) => Promise<boolean>,
  getRivalitasUsers: () => Promise<RivalitasUser[]>,
};

async function apiCallResolver(apiCall:any, silent:boolean=false, loadingMsg: boolean=false) {
  let apiResult = null;

  // if (!silent) {
  //   channel.publish('ajax.start', {});
  // }
  // if (loadingMsg) {
  //   channel.publish('ajax.loadingstart', {});
  // }

  try
  {
    const response = await apiCall();

    if (response.config.method === 'post' || response.config.method === 'put') {
      // if (!silent) {
      //   channel.publish('ajax.save', 'Data have been saved');
      // }
    }

    if (response.config.method === 'delete') {
      // if (!silent) {
      //   channel.publish('ajax.delete', 'Data have been deleted');
      // }
    }

    apiResult = {
      success: true,
      data: response.data,
    };
  }
  catch(e:any) {
    window.console.error(e);
  }

  // if (!silent) {
  //   channel.publish('ajax.end', {});
  // }
  // if (loadingMsg) {
  //   channel.publish('ajax.loadingend', {});
  // }

  return apiResult;
}


export const RivalitasApiMock: IRivalitasApi = {
  setAccessToken: function (accessToken: string): void {
    throw new Error('Function not implemented.');
  },
  list: function (query: any): Promise<any> {
    throw new Error('Function not implemented.');
  },
  command: function (type: string, command: any): Promise<any> {
    throw new Error('Function not implemented.');
  },
  delete: function (entity: string, entityId: string): Promise<boolean> {
    throw new Error('Function not implemented.');
  },
  getRivalitasUsers: function (): Promise<RivalitasUser[]> {
    throw new Error('Function not implemented.');
  },
};

function createRivalitasApi(): IRivalitasApi {
  const apiClient = axios.create();

  const apiUrl = config.apiUrl;

  return {
    setAccessToken: (accessToken:string) => {
      const headers = _.cloneDeep(apiClient.defaults.headers);
      headers.common.Authorization = `Bearer ${accessToken}`;

      apiClient.defaults.headers = headers;
    },
    list: async (query:any) => {
      const apiResult = await apiCallResolver(() => apiClient.post(
        `${apiUrl}/list`,
        query,
      ));

      return apiResult?.success ? apiResult?.data?.result ?? [] : [];
    },
    command: async (type: string, command: any): Promise<any> => {
      const apiResult = await apiCallResolver(() => apiClient.post(
        `${apiUrl}/command`,
        {
          type,
          command
        },
      ));

      return apiResult?.success ? apiResult.data : null;
    },
    delete: async (entity: string, entityId: string): Promise<boolean> => {
      const apiResult = await apiCallResolver(() => apiClient.delete(
        `${apiUrl}/list/${entity}/${entityId}`,
      ));

      return apiResult?.success ? apiResult?.data : false;
    },
    getRivalitasUsers: async (): Promise<RivalitasUser[]> => {
      const apiResult = await apiCallResolver(() => apiClient.get(
        'https://login.rivalitas.hr/login/users',
      ));

      return apiResult?.success ? apiResult?.data : [];
    }
  };
}

export default createRivalitasApi;
