import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import rivalitasMenus, { Job, Menu } from '../navigation/menus';

import {
  IconLock,
  IconSettings,
} from '@tabler/icons';

import routes from '../config/routes';

import Avatar from '../img/avatar.png';
import FlexSpacer from '../ui/FlexSpacer';
import RivButton from '../ui/RivButton';
import { useRivalitasStore } from '../store';
import WidthSpacer from '../ui/WidthSpacer';
import JobMenu from '../components/JobMenu';
import useJob from '../hooks/useJob';
import createApi from '../api';
import ApplicationContext from '../ApplicationContext';
import { IRivalitasApi } from '../api/rivalitasApi';
import { ConstructionType, OfferType, RealEstateType, RivalitasUser } from '../models';
import RivModal from '../ui/RivModal';

Modal.setAppElement('#root');

function RivalitasMain() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    accessToken,
    logout,
    name,
    email,
    globalOkDialog,
    hideGlobalOkDialog,
    globalYesNoDialog,
    hideGlobalYesNoDialog,
  } = useRivalitasStore();
  const { openJob } = useJob();

  const [selectedMenu, setSelectedMenu] = useState<Menu|null>(null);

  const [constructionTypes, setConstructionTypes] = useState<ConstructionType[]>([]);
  const [offerTypes, setOfferTypes] = useState<OfferType[]>([]);
  const [realEstateTypes, setRealEstateTypes] = useState<RealEstateType[]>([]);
  const [rivalitasUsers, setRivalitasUsers] = useState<RivalitasUser[]>([]);

  const apiRef = useRef<IRivalitasApi>(createApi());
  const registersLoadedRef = useRef(false);
  const usersLoadedRef = useRef(false);

  const currentUrl = location.pathname;

  useEffect(() => {
    apiRef.current.setAccessToken(accessToken);

    loadRegisters();
    loadRivalitasUsers();

    // Back handler - don't allow it. Depend on the double push logic inside navigation helpers
    window.addEventListener("popstate", handlePopState);
    return () => {
      registersLoadedRef.current = false;
      window.removeEventListener("popstate", handlePopState);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function openUrl(url: string) {
    // Duplicate push so we can handle back button in correct way
    navigate(url);
    navigate(url);
  }

  async function loadRegisters() {
    if (registersLoadedRef.current) {
      return;
    }

    const listObj = {
      ConstructionType: {
        Sort: '{$sort: {Type: 1}}',
      },
      OfferType: {
        Sort: '{$sort: {Type: 1}}',
      },
      RealEstateType: {
        Sort: '{$sort: {Type: 1}}',
      },
    };

    const result = await apiRef.current.list(listObj);

    setConstructionTypes(result['ConstructionType'] ?? []);
    setOfferTypes(result['OfferType'] ?? []);
    setRealEstateTypes(result['RealEstateType'] ?? []);

    registersLoadedRef.current = true;
  }

  async function loadRivalitasUsers() {
    if (usersLoadedRef.current) {
      return;
    }

    const ru = await apiRef.current.getRivalitasUsers();
    setRivalitasUsers(ru);

    usersLoadedRef.current = true;
  }

  function handlePopState() {
    window.console.log('popstate blocked');
    navigate(1);
  }

  function logoutUser() {
    logout();
    window.location.hash = '';
    window.location.reload(); // TODO
  }

  function navigateJob(job: Job) {
    openJob(job.url);
  }

  function closeGlobalOk() {
    if (globalOkDialog.resolve) {
      globalOkDialog.resolve();
    }
    hideGlobalOkDialog();
  }

  function processGlobalYesNoClick(command: 'Yes'|'No') {
    if (globalYesNoDialog.resolve) {
      globalYesNoDialog.resolve(command === 'Yes');
    }
    hideGlobalYesNoDialog();
  }

  return (
    <ApplicationContext.Provider
      value={{
        agents: rivalitasUsers.filter(x => x.enabled && x.claims.some(c => c === 'rivalitas:agent')),
        api: apiRef.current,
        constructionTypes,
        offerTypes,
        realEstateTypes,
        currentUserEmail: email,
        currentUserName: name,
        defaultConstructionType: constructionTypes.find(x => x.type === 'Zgrada') || {id: '', type: ''},
        defaultOfferType: offerTypes.find(x => x.type === 'Prodaja') || {id: '', type: ''},
        defaultRealEstateType: realEstateTypes.find(x => x.type === 'Stanovi') || {id: '', type: ''},
        openUrl,
      }}
    >
      <div id="rivalitas-root">
        <div id="rivalitas-top-menu">
          <img src={Avatar} style={{height: 30}} alt="Rivalitas Avatar" />
          <WidthSpacer width="15px" />

          {rivalitasMenus.map(m =>
            <div key={m.urlBase} style={{display: 'flex'}}>
              <RivButton onClick={() => setSelectedMenu(m)} type="transparent" disabled={m.disabled}>
                {m.label}
              </RivButton>
              <WidthSpacer width="5px" />
            </div>)}
          <FlexSpacer />

          <div style={{marginRight: '15px'}}>
            <RivButton type="transparent">
              <IconSettings color="#ffffff" size={12} />
            </RivButton>
          </div>

          <div style={{marginRight: '15px'}}>
            {name}
          </div>
          <RivButton type="transparent" onClick={logoutUser}>
            <IconLock color="#ffffff" size={12} />
          </RivButton>
        </div>

        <div id="rivalitas-selected-menu">
          {!!selectedMenu &&
            <>
              {selectedMenu.jobs.map(j => (
                <div className="rivalitas-selected-menu-item-container" key={`${selectedMenu.urlBase}-${j.url}`}>
                  <RivButton
                    type={currentUrl === j.url ? 'secondary' : 'primary'}
                    disabled={j.disabled}
                    onClick={() => navigateJob(j)}
                  >{j.label}
                  </RivButton>
                </div>
              ))}
            </>
          }
        </div>

        <JobMenu />

        <div id="rivalitas-main-content">
          {routes}
        </div>
        <div id="rivalitas-footer">
          &copy; Rivalitas d.o.o 2023
        </div>
        <RivModal isOpen={globalOkDialog.show} width="350px" noTitle>
          {globalOkDialog.content}
          <div style={{float: 'right', marginBottom: '10px'}}>
            <RivButton type="primaryInverse" onClick={closeGlobalOk}>Ok</RivButton>
          </div>
        </RivModal>
        <RivModal isOpen={globalYesNoDialog.show} width="350px" noTitle>
          {globalYesNoDialog.content}
          <div style={{float: 'right', marginBottom: '10px'}}>
            <RivButton type="primaryInverse" onClick={() => processGlobalYesNoClick('No')}>
              Ne
            </RivButton>&nbsp;
            <RivButton type="primary" onClick={() => processGlobalYesNoClick('Yes')}>Da</RivButton>
          </div>
        </RivModal>
        <div id="rivalitas-app-poppers" style={{position: 'fixed', bottom: '0', zIndex: 999999}}>
        </div>
      </div>
    </ApplicationContext.Provider>
  );
}

export default RivalitasMain;
