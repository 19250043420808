import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import {
  IconX
} from '@tabler/icons';

import useFunctionKeys from '../hooks/useFunctionKeys';

const ModalContentStyle:React.CSSProperties = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  padding: '0px',
  zIndex: 10000,
};
const ModalOverlayStyle:React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.55)',
  zIndex: 999998,
};

const BlueModalContainer = styled.div`

  .r-modal-header {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 20px;
    background: #062f4f;
    color: white;
    height: 50px;
    h1 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      display: inline-block;
      margin: 0 !important;
    }

    .r-modal-close {
      font-family: sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .r-modal-content {
    padding: 20px;
  }

  .r-modal-header-draggable {
    cursor: all-scroll;
  }
`;

function RivModal({
  isOpen,
  title = undefined,
  onCancel = undefined,
  top = undefined,
  width = undefined,
  children = undefined,
  onShow = () => {},
  onHide = () => {},
  cancelOnEsc = false,
  noTitle = false,
}: {
  isOpen: boolean,
  title?: React.ReactNode|string|undefined,
  onCancel?: ((x:React.MouseEvent|string) => void)|undefined,
  top?: string|undefined,
  width?: string|undefined,
  children?: React.ReactNode|string|null|undefined,
  onShow?: () => void,
  onHide?: () => void,
  cancelOnEsc?: boolean,
  noTitle?: boolean,
}) {
  useFunctionKeys({onEsc: onEscape});

  const [toggle, setToggle] = useState(isOpen);

  const modalRef = useRef(null);

  const widthStyle = !!width ? { width } : { width: '900px'};

  useEffect(() => {
    if (toggle !== isOpen) {
      setToggle(isOpen);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (toggle) {
      if (onShow) {
        onShow();
      }
    } else {
      if (onHide) {
        onHide();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  function onEscape() {
    if (cancelOnEsc && onCancel) {
      onCancel('esc');
      return true;
    }
    return false;
  }

  const headerBaseClass = 'r-modal-header';

  const additionalContextStyle:React.CSSProperties = top ? { top } : {};

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel=""
      style={{
        overlay: ModalOverlayStyle,
        content: {
          ...ModalContentStyle,
          ...additionalContextStyle,
        },
      }}
    >
      <BlueModalContainer ref={modalRef}>
        <div style={widthStyle}>
          {!noTitle &&
            <div className={headerBaseClass}>
              <h1 className="r-modal-header-h">{title}</h1>
              <span className="r-modal-close" onClick={onCancel}>
                <IconX size={16} />
              </span>
            </div>
          }

          <div className="r-modal-content">
            {children}
            <div className="clearfix"></div>
          </div>
        </div>
      </BlueModalContainer>
    </Modal>
  );
}

export default React.memo(RivModal);
