const config = {
  auth0: {
    audience: 'RivalitasApi',
    authEndpoint: 'https://login.rivalitas.hr/login',
    clientId: 'Lv0o30e3bNbSSTJmZ2i1VtXV4JCcxUjx',
    domain: 'rivalitas.eu.auth0.com',
    responseType: 'token',
    scope: 'openid profile email picture rivalitasapi',
  },
  apiUrl: process.env.NODE_ENV === 'production'
    ? 'https://v3.rivalitas-api.cc'
    : 'http://localhost:8080',
};

// if (process.env.NODE_ENV === 'production') {
//   var loc = window.location, ws_uri;

//   if (loc.protocol === 'https:') {
//   	ws_uri = 'wss:';
//   } else {
//   	ws_uri = 'ws:';
//   }
//   ws_uri += '//' + loc.host + '/ws';

//   config.websocketUrl = ws_uri;
//   config.serverUrl = '/';
// } else {
//   config.serverUrl = 'http://localhost:5000/';
//   config.websocketUrl = 'ws://localhost:5000/ws';
// }

// config.apiUrl = config.serverUrl + 'api/1/';

export default config;
