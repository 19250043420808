import './rivButtons.css';
import React from 'react';

function getTypeClass(type: 'primary'|'secondary'|'transparent'|'primaryInverse'|'danger') {
  if (type === 'primary') {
    return 'r-btn-primary';
  } else if (type === 'secondary') {
    return 'r-btn-secondary';
  } else if (type === 'primaryInverse') {
    return 'r-btn-primary-inverse';
  } else if (type === 'danger') {
    return 'r-btn-danger';
  } else {
    return 'r-btn-transparent';
  }
}

function getSizeClass(size: 'regular'|'small'|'large') {
  if (size === 'regular') {
    return 'r-btn-regular';
  } else if (size === 'small') {
    return 'r-btn-small';
  } else {
    return 'r-btn-large';
  }
}

export interface RivButtonProps {
  disabled?: boolean,
  children?: React.ReactNode | undefined,
  size?: 'regular'|'small'|'large',
  type?: 'primary'|'secondary'|'transparent'|'primaryInverse'|'danger',
  onClick?: VoidFunction,
  style?: React.CSSProperties|undefined,
};

function RivButton(
  {
    disabled = false,
    children = undefined,
    size = 'regular',
    type = 'primary',
    onClick = () => {},
    style = undefined,
  } : RivButtonProps
) {
  const classNames = `r-btn${disabled ? '' : ' r-tabable'} ${getTypeClass(type)} ${getSizeClass(size)}`;

  return (
    <button className={classNames} disabled={disabled} onClick={onClick} style={style}>
      {children}
    </button>
  );
}

export default React.memo(RivButton);
