export interface Menu {
  label: React.ReactNode,
  urlBase: string,
  disabled: boolean,
  jobs: Job[],
};

export interface Job {
  label: React.ReactNode,
  url: string,
  disabled: boolean,
}

const RivalitasMenus:Menu[] = [
  {
    label: <span>Nekretnine</span>,
    urlBase: '/realEstates',
    disabled: false,
    jobs: [
      {
        label: <span>Aktivne</span>,
        url: '/realEstates/active/list',
        disabled: false,
      },
      {
        label: <span>Neaktivne</span>,
        url: '/realEstates/inactive/list',
        disabled: false,
      },
    ],
  },
  {
    label: <span>CRM</span>,
    urlBase: '/crm',
    disabled: false,
    jobs: [
      {
        label: <span>Kupci</span>,
        url: '/crm/buyer/list',
        disabled: false,
      },
      {
        label: <span>Prodavatelji</span>,
        url: '/crm/seller/list',
        disabled: false,
      },
      {
        label: <span>Prevaranti</span>,
        url: '/crm/fraudster/list',
        disabled: false,
      },
    ],
  },
  { // TODO: only for admins
    label: <span>Registri</span>,
    urlBase: '/registers',
    disabled: false,
    jobs: [
      {
        label: <span>Lokacije</span>,
        url: '/registers/location/list',
        disabled: false,
      },
      {
        label: <span>Zaglavlja</span>,
        url: '/registers/header/list',
        disabled: false,
      },
      {
        label: <span>Podnožja</span>,
        url: '/registers/footer/list',
        disabled: false,
      },
      {
        label: <span>Vrste ponude</span>,
        url: '/registers/offerType/list',
        disabled: false,
      },
      {
        label: <span>Vrste gradnje</span>,
        url: '/registers/constructionType/list',
        disabled: false,
      },
      {
        label: <span>Tipovi nekretnina</span>,
        url: '/registers/realEstateType/list',
        disabled: false,
      },
    ],
  },
];

export default RivalitasMenus;
