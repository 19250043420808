import _ from 'lodash';
import { Route, Routes } from 'react-router-dom';

import { RivalitasRoute } from './types';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

import crmRoutes from '../pages/crm/routes';
import realEstateRoutes from '../pages/realEstates/routes';
import registerRoutes from '../pages/registers/routes';

const AppRoutes = [
  ...crmRoutes,
  ...realEstateRoutes,
  ...registerRoutes,
];

const routeExpander = (routes:Array<RivalitasRoute>) =>
  routes.map(r => {
    const Component = r.component;
    return <Route key={_.uniqueId('route-')} path={r.path} element={<Component />} />;
  });

const routes = (
  <Routes>
    {routeExpander(AppRoutes)}
    <Route path="/" element={<Home />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default routes;
