import React from 'react';

function NotFound() {
  return (
    <div>
      Tražena stranica ne postoji
    </div>
  );
}

export default React.memo(NotFound);