import React from 'react';
import { IRivalitasApi, RivalitasApiMock } from './api/rivalitasApi';
import { ConstructionType, OfferType, RealEstateType, RivalitasUser } from './models';

export interface IApplicationContext {
  agents: RivalitasUser[],
  api: IRivalitasApi,
  constructionTypes: ConstructionType[],
  offerTypes: OfferType[],
  realEstateTypes: RealEstateType[],
  currentUserName: string,
  currentUserEmail: string,
  defaultConstructionType: ConstructionType,
  defaultOfferType: OfferType,
  defaultRealEstateType: RealEstateType,
  openUrl: (url: string) => void,
};

const defaultState: IApplicationContext = {
  agents: [],
  api: RivalitasApiMock,
  constructionTypes: [],
  offerTypes: [],
  realEstateTypes: [],
  currentUserName: '',
  currentUserEmail: '',
  defaultConstructionType: {
    id: '',
    type: '',
  },
  defaultOfferType: {
    id: '',
    type: '',
  },
  defaultRealEstateType: {
    id: '',
    type: '',
  },
  openUrl: () => {},
};

const ApplicationContext = React.createContext<IApplicationContext>(defaultState);

export default ApplicationContext;
