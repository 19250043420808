import React from 'react';

import { RivalitasRoute  } from '../../../config/types';

const ActiveRealEstateList = React.lazy(() => import('../active/ActiveRealEstateList'));
const InactiveRealEstateList = React.lazy(() => import('../inactive/InactiveRealEstateList'));
const RealEstateView = React.lazy(() => import('../RealEstateView'));

const routes: Array<RivalitasRoute> = [
  {path: '/realEstates/active/list', component: ActiveRealEstateList},
  {path: '/realEstates/inactive/list', component: InactiveRealEstateList},
  {path: '/realEstates/view/:id?', component: RealEstateView},
];

export default routes;
