import React from 'react';

import { RivalitasRoute  } from '../../../config/types';

const BuyerView = React.lazy(() => import('../buyers/BuyerView'));
const BuyerListView = React.lazy(() => import('../buyers/BuyerList'));
const BuyerForRealEstateList = React.lazy(() => import('../buyers/BuyerForRealEstateList'));
const FraudsterView = React.lazy(() => import('../fraudsters/FraudsterView'));
const FraudsterListView = React.lazy(() => import('../fraudsters/FraudsterList'));
const SellerView = React.lazy(() => import('../sellers/SellerView'));
const SellerListView = React.lazy(() => import('../sellers/SellerList'));

const routes: Array<RivalitasRoute> = [
  {path: '/crm/buyer/list', component: BuyerListView},
  {path: '/crm/buyerForRealEstate/list/:realEstateId', component: BuyerForRealEstateList},
  {path: '/crm/buyer/view/:id?', component: BuyerView},
  {path: '/crm/fraudster/list', component: FraudsterListView},
  {path: '/crm/fraudster/view/:id?', component: FraudsterView},
  {path: '/crm/seller/list', component: SellerListView},
  {path: '/crm/seller/view/:id?', component: SellerView},
];

export default routes;
