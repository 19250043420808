import Auth0Lock from 'auth0-lock';
import Auth0LockStatic from 'auth0-lock';
import {Buffer} from 'buffer'
import React, { useEffect } from 'react';
import config from '../config';
import { useRivalitasStore } from '../store';

// Guard from calling auth0Login twice in dev mode (StrictMode calls useEffect twice)
let auth0Initiated = false;
let lock: Auth0LockStatic|null = null;

function Login() {
  const { login } = useRivalitasStore();

  useEffect(() => {
    auth0Login();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function auth0Login() {
    if (auth0Initiated) {
      return;
    }

    auth0Initiated = true;

    lock = new Auth0Lock(
      config.auth0.clientId,
      config.auth0.domain,
      {
        closable: false,
        auth: {
          audience: config.auth0.audience,
          redirectUrl: config.auth0.authEndpoint,
          responseType: config.auth0.responseType,
          responseMode: 'form_post',
          params: {
            scope: config.auth0.scope,
            state: Buffer.from(`${window.location.protocol}//${window.location.host}`).toString('base64'),
          },
        },
        theme: {
          logo: 'https://s3.eu-central-1.amazonaws.com/brand.rivalitas-api.cc/AV1-transparent.png'
        },
        languageDictionary: {
          title: 'Rivalitas',
        }
      },
    );

    lock.on('authenticated', (authResult) => {
      if (authResult.accessToken) {
        login(authResult.accessToken);
      }
    });

    if ( !(/access_token|id_token|error/.test(window.location.hash)) ) {
      lock.show();
    }
  }

  return (
    <div>
    </div>
  );
}

export default React.memo(Login);
