import { useEffect } from 'react';

const FKeys = [
  "F1",
  "F2",
  "F3",
  "F4",
  "F5",
  "F6",
  "F7",
  "F8",
  "F9",
  "F10",
  "F12",
  "F13",
];

export type EscHandler = () => boolean;
export type EnterHandler = (evt:KeyboardEvent) => boolean;
export type UpHandler = () => boolean;
export type DownHandler = () => boolean;
export type LeftHandler = () => boolean;
export type RightHandler = () => boolean;
export type FunctionHandler = (f:string) => boolean;
export type AltCharHandler = (f:string) => boolean;

/**
 * Check if keyboard event is key press (not function or special key)
 *
 * @export
 * @param {KeyboardEvent} evt
 * @return {*} 
 */
function isCharacterKeyPress(evt:KeyboardEvent|React.KeyboardEvent) {
  return (!evt.keyCode) ||
    (
      !(evt.keyCode <= 29 ) &&
      !(evt.keyCode >= 33 && evt.keyCode <= 47) &&
      !(evt.keyCode >= 91 && evt.keyCode <= 95) &&
      !(evt.keyCode >= 112 && evt.keyCode <= 151) &&
      !(evt.keyCode >= 166 && evt.keyCode <= 169) &&
      !(evt.keyCode >= 172 && evt.keyCode <= 183) &&
      !(evt.keyCode >= 224 && evt.keyCode <= 235)
    );
}

interface FunctionKeysProps {
  onEsc?: EscHandler,
  onEnter?: EnterHandler,
  onFunction?: FunctionHandler,
  onAltChar?: AltCharHandler,
  onUp?: UpHandler,
  onDown?: DownHandler,
  onLeft?: LeftHandler,
  onRight?: RightHandler,
};

/**
 * useFunctionKeys - hook for registering callback functions for various special keyboard keys
 *
 * @export
 * @param {FunctionKeysProps} {
 *   onEsc, - callback for Esc key
 *   onEnter,  - callback for Enter key
 *   onFunction, - callback for F key - receives function key being pressed as string parameter
 *   onAltChar, - callback for Alt + Char - receives char key being pressed as string parameter
 *   onUp, - callback for key up
 *   onDown - callback for key down
 * }
 */
export default function useFunctionKeys({
  onEsc,
  onEnter,
  onFunction,
  onAltChar,
  onUp,
  onDown,
  onLeft,
  onRight,
}:FunctionKeysProps) {
  useEffect(() => {
    document.addEventListener('keydown', handle);
    return () => document.removeEventListener('keydown', handle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handle(evt:KeyboardEvent) {
    evt = evt || window.event;
    let isEscape = false;
    let isEnter = false;
    let isFunction = false;
    const isAltChar = evt.altKey && isCharacterKeyPress(evt);
    let fkey = "";
    let isUp = false;
    let isDown = false;
    let isLeft = false;
    let isRight = false;

    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
        isEnter = evt.key === 'Enter';
        isFunction = FKeys.includes(evt.key);
        fkey = isFunction ? evt.key : "";
        isDown = evt.key === 'ArrowDown';
        isUp = evt.key === 'ArrowUp';
        isLeft = evt.key === 'ArrowLeft';
        isRight = evt.key === 'ArrowRight';
    }

    if (isEscape && onEsc && !evt.cancelBubble) {
      evt.preventDefault();
      const escHandled = onEsc();
      if (escHandled) {
        evt.stopPropagation();
      }
    }

    if (isEnter && onEnter && !evt.cancelBubble) {
      onEnter(evt);
      evt.preventDefault();
    }

    if (isFunction && onFunction && !evt.cancelBubble) {
      let actualFKey = fkey;
      if (evt.altKey) {
        actualFKey = `ALT+${actualFKey}`;
      }
      if (evt.shiftKey) {
        actualFKey = `SHIFT+${actualFKey}`;
      }
      if (evt.ctrlKey) {
        actualFKey = `CTRL+${actualFKey}`;
      }
      const functionHandled = onFunction(actualFKey);
      if (functionHandled) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }

    if (isAltChar && onAltChar) {
      let char = String.fromCharCode(evt.keyCode);
      if (char) {
        char = char.toLowerCase();
      }

      if (char) {
        const onAltCharHandled = onAltChar(char);
        if (onAltCharHandled) {
          evt.preventDefault();
          evt.stopPropagation();
        }
      }
    }

    if (isUp && onUp) {
      const handled = onUp();
      if (handled) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
    if (isDown && onDown) {
      const handled = onDown();
      if (handled) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
    if (isLeft && onLeft) {
      const handled = onLeft();
      if (handled) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
    if (isRight && onRight) {
      const handled = onRight();
      if (handled) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
  }
}
  