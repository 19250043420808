import './jobMenu.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  IconFilter,
  IconSquareX,
} from '@tabler/icons';
import { ScreenStackElement, useRivalitasStore } from '../store';
import { useLocation } from 'react-router';
import useJob from '../hooks/useJob';
import storage from '../storage';
import { ListState } from './views/ListView';
import { Subject } from 'rxjs/internal/Subject';
import { jobMenu$ } from '../pubsub/jobMenu';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

function JobMenu() {
  const { screenStack } = useRivalitasStore();
  const { closeJob, openJob } = useJob();

  const [destroy$] = useState(new Subject<boolean>());
  const [,_setJobMenuTick] = useState(0);
  const tickRef = useRef(0);

  function setJobMenuTick(t: number) {
    tickRef.current = t;
    _setJobMenuTick(t);
  }

  const location = useLocation();

  const currentUrl = location.pathname;

  useEffect(() => {
    jobMenu$
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        setJobMenuTick(tickRef.current + 1);
      });

    return () => {
      destroy$.next(true);
		  destroy$.complete();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCloseJobClicked(e: React.MouseEvent, s: ScreenStackElement) {
    e.stopPropagation();
    e.preventDefault();
    closeJob(s.url);
  }

  function getJobClassNames(s: ScreenStackElement) {
    const selected = s.url === currentUrl;
    return `rivalitas-job-menu-item${selected ? ' rivalitas-job-menu-item-selected' : ''}`;
  }

  function switchJobMenu(s: ScreenStackElement) {
    const selected = s.url === currentUrl;
    if (!selected) {
      openJob(s.fullUrl);
    }
  }

  function getFilterIcon(s: ScreenStackElement) {
    const urlDelimiter = `${s.url}|`;
    const storageKey = `${urlDelimiter}-list-items`;

    const currentState = storage[storageKey] as ListState<unknown>|undefined;
    if (currentState?.search) {
      return <IconFilter size={12} style={{color: '#062f4f', position: 'relative', top: '2px'}} />;
    }

    return null;
  }

  return (
    <div id="rivalitas-job-menu">
      {screenStack.map((s, i) => (
        <div key={s.url} onClick={() => switchJobMenu(s)} className={getJobClassNames(s)}>
          <div className="rivalitas-job-menu-item-container">
            {getFilterIcon(s)}
            {s.label}
          </div>
          {i > 0 &&
            <div className="rivalitas-job-menu-item-container-close" onClick={(e) => onCloseJobClicked(e, s)}>
              <IconSquareX size={12} />
            </div>
          }
        </div>
      ))}
    </div>
  );
}

export default React.memo(JobMenu);
