import { DateTime } from 'luxon';
import create from 'zustand';
import decodeJwt from 'jwt-decode';

export interface ScreenStackElement {
  url: string,
  fullUrl: string,
  label: string,
  time: number,
};

export interface GlobalOkDialogState {
  show: boolean,
  resolve: ((value: void | PromiseLike<void>) => void)|null,
  content: React.ReactNode | null,
};

export interface GlobalYesNoDialogState {
  show: boolean,
  resolve: ((value: boolean | PromiseLike<boolean>) => void)|null,
  content: React.ReactNode | null,
};

interface RivalitasState {
  isLoggedIn: boolean,
  accessToken: string,
  name: string,
  email: string,
  picture: string,
  expiresAt: number,
  login: (accessToken: string) => void,
  logout: VoidFunction,
  /* Screen stack */
  screenStack: ScreenStackElement[],
  pushScreen: (url: string, label: string, fullUrl: string) => void,
  removeScreen: (url: string) => void,
  /* Global OK dialog */
  globalOkDialog: GlobalOkDialogState,
  showGlobalOkDialog: (content: React.ReactNode | null, resolve: ((value: void | PromiseLike<void>) => void)|null) => void,
  hideGlobalOkDialog: VoidFunction,
  /* Global YES/NO dialog */
  globalYesNoDialog: GlobalYesNoDialogState,
  showGlobalYesNoDialog: (content: React.ReactNode | null, resolve: ((value: boolean | PromiseLike<boolean>) => void)|null) => void,
  hideGlobalYesNoDialog: VoidFunction,
}

export const useRivalitasStore = create<RivalitasState>((set) => ({
  // Default state
  isLoggedIn: false,
  accessToken: '',
  name: '',
  email: '',
  picture: '',
  expiresAt: 0,
  screenStack: [{url: '/', label: 'Home', time: DateTime.now().valueOf(), fullUrl: '/'}],
  globalOkDialog: {
    show: false,
    resolve: null,
    content: null,
  },
  globalYesNoDialog: {
    show: false,
    resolve: null,
    content: null,
  },

  // State change functions
  login: (accessToken: string) => {
    const accessTokenData: any = decodeJwt(accessToken);

    set((/*state*/) => ({
      isLoggedIn: true,
      accessToken,
      name:  accessTokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      email: accessTokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
      picture: accessTokenData.picture,
      expiresAt: accessTokenData.exp * 1000,
    }));

  },
  logout: () => {
    set((/*state*/) => ({
      isLoggedIn: false,
      accessToken: '',
      name: '',
      email: '',
      picture: '',
      expiresAt: 0,
    }));
  },
  pushScreen: (url: string, label: string, fullUrl: string) => {
    set((state) => {
      const screenStack = [...state.screenStack];
      const existingIndex = screenStack.findIndex(x => x.url === url);
      if (existingIndex >= 0) {
        screenStack[existingIndex].time = DateTime.now().valueOf();
        screenStack[existingIndex].label = label;
        screenStack[existingIndex].fullUrl = fullUrl;
      } else {
        screenStack.push({url, label, time: DateTime.now().valueOf(), fullUrl});
      }

      return {
        screenStack,
      };
    });
  },
  removeScreen: (url: string) => {
    set((state) => {
      if (state.screenStack.length <= 1) {
        return {}; // Last screen must be on the screen
      }
      const screenStack = state.screenStack.filter(x => x.url !== url);

      return {
        screenStack,
      };
    });
  },
  showGlobalOkDialog: (content: React.ReactNode | null, resolve: ((value: void | PromiseLike<void>) => void)|null) => {
    set(() => {
      return {
        globalOkDialog: {
          show: true,
          content,
          resolve,
        }
      }
    });
  },
  hideGlobalOkDialog: () => {
    set(() => {
      return {
        globalOkDialog: {
          show: false,
          content: null,
          resolve: null,
        }
      }
    });
  },
  showGlobalYesNoDialog: (content: React.ReactNode | null, resolve: ((value: boolean | PromiseLike<boolean>) => void)|null) => {
    set(() => {
      return {
        globalYesNoDialog: {
          show: true,
          content,
          resolve,
        }
      }
    });
  },
  hideGlobalYesNoDialog: () => {
    set(() => {
      return {
        globalYesNoDialog: {
          show: false,
          content: null,
          resolve: null,
        }
      }
    });
  },
}));
