import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import RivalitasMain from './pages/RivalitasMain';
import { useRivalitasStore } from './store';

function App() {
  const { isLoggedIn } = useRivalitasStore();
  
  return isLoggedIn
    ?
      <BrowserRouter>
        <React.Suspense fallback={<div></div>}>
          <RivalitasMain />
        </React.Suspense>
      </BrowserRouter>
    : <Login />;
}

export default App;
