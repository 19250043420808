import React from 'react';

import { RivalitasRoute  } from '../../../config/types';

const FooterView = React.lazy(() => import('../footers/FooterView'));
const FooterListView = React.lazy(() => import('../footers/FooterList'));
const HeaderView = React.lazy(() => import('../headers/HeaderView'));
const HeaderListView = React.lazy(() => import('../headers/HeaderList'));
const ConstructionTypeListView = React.lazy(() => import('../constructionTypes/ConstructionTypeList'));
const OfferTypeListView = React.lazy(() => import('../offerTypes/OfferTypeList'));
const RealEstateTypeListView = React.lazy(() => import('../realEstateTypes/RealEstateTypeList'));

const routes: Array<RivalitasRoute> = [
  {path: '/registers/footer/list', component: FooterListView},
  {path: '/registers/footer/view/:id?', component: FooterView},
  {path: '/registers/header/list', component: HeaderListView},
  {path: '/registers/header/view/:id?', component: HeaderView},
  {path: '/registers/constructionType/list', component: ConstructionTypeListView},
  {path: '/registers/offerType/list', component: OfferTypeListView},
  {path: '/registers/realEstateType/list', component: RealEstateTypeListView},
];

export default routes;
